'use strict';
import {
  TweenMax
} from "gsap/umd/TweenMax";

const self = {
  container: document.querySelector('.people-container'),
  tabs: null,
  texts: null,

  init: () => {
    TweenMax.fromTo('.people-filters', 0.3, {
      autoAlpha: 0,
      y: '20px'
    }, {
      autoAlpha: 1,
      y: '0px',
      delay: 0.3
    });
    self.setupElements();
    self.accordionActive();
  },

  setupElements: () => {
    self.container = document.querySelector('.people-container');
    self.tabs = [...document.querySelectorAll('.people-filters button')];
    self.contents = [...document.querySelectorAll('.page-content')];
  },

  accordionActive: () => {
    self.tabs.forEach((tab) => {
      tab.addEventListener('click', (e) => {

        const currentTab = e.currentTarget
        if (currentTab.classList.contains('is-active')) return false

        currentTab.parentNode.querySelector('button.is-active').classList.remove('is-active');
        currentTab.classList.add('is-active');

        self.contents.forEach((content) => {
          content.classList.remove('is-active');
          if (e.currentTarget.dataset.tab === content.dataset.content) {
            content.classList.add('is-active');
            const cards = document.querySelectorAll('.l-lawyers.is-active .card');
            TweenMax.staggerFromTo(cards, 1, {
              autoAlpha: 0,
              y: '20px'
            }, {
              autoAlpha: 1,
              y: '0px'
            }, 0.1);
          }
        });

      });
    });
  }

}

export default self