'use strict'

import { TimelineLite } from 'gsap/umd/TweenMax'
import throttle from 'lodash.throttle'

const self = {
  body: null,
  header: null,
  headerInner: null,
  hamburger: null,
  navList: null,
  lastScrollTop: 0,

  init: () => {
    self.setupElements()
    window.addEventListener('scroll', throttle(self.onScroll, 16))
    window.addEventListener('resize', self.resize)
    self.resize()
  },

  setupElements: () => {
    self.body = document.querySelector('body')
    self.header = document.querySelector('header')
    self.headerInner = document.querySelector('.header .header-inner')
    self.hamburger = document.querySelector('.mobile-hamburger-container')
    self.navList = document.querySelectorAll('.main-nav > li')
    self.lastScrollTop = 0
  },

  resize: () => {
    if (window.innerWidth < 800) {
      self.hamburger.addEventListener('click', () => {
        if (!document.body.classList.contains('is-animating')) {
          self.toggle()
        }
      })
    } else {
      self.revealSubnav()
    }
  },

  toggle: () => {
    if (!self.body.classList.contains('is-open')) {
      self.openNav()
    } else {
      self.closeNav()
    }
  },

  openNav: () => {
    const tlOpen = new TimelineLite()
    tlOpen
      .set(self.body, { className: '+=is-animating' })
      .set(self.body, { className: '+=is-open', overflow: 'hidden' })
      .set(self.navList, { y: '20px', autoAlpha: 0 })
      .set(self.headerInner, { display: 'flex' })
      .to(self.headerInner, 0.4, { autoAlpha: 1 })
      .staggerTo(self.navList, 0.9, { y: '0px', autoAlpha: 1, ease: Expo.easeOut }, 0.1, '-=0.1')
      .set(self.body, { className: '-=is-animating' })
  },

  closeNav: () => {
    const tlClose = new TimelineLite()
    tlClose
      .set(self.body, { className: '+=is-animating' })
      .set(self.body, { className: '-=is-open' })
      .staggerTo(self.navList, 0.9, { y: '20px', autoAlpha: 0, ease: Expo.easeOut }, -0.1)
      .to(self.headerInner, 0.4, { autoAlpha: 0 }, '-=0.3')
      .set(self.headerInner, { display: 'none' })
      .set([self.mainNav, self.navList], { clearProps: 'all' })
      .set(self.body, { className: '-=is-animating', overflow: 'visible' })
  },

  revealSubnav: () => {
    const lists = [...document.querySelectorAll('.have-sub-nav')]
    const subNav = [...document.querySelectorAll('.sub-nav-inner')]

    lists.forEach(list => {
      list.addEventListener('click', function(e) {
        e.preventDefault()

        //remove all is-acitve class
        lists.forEach(l => {
          if (this !== l) {
            l.classList.remove('is-active')
            l.parentElement.nextElementSibling.classList.remove('is-active')
          }
        })

        //active
        this.classList.toggle('is-active')
        this.parentElement.nextElementSibling.classList.toggle('is-active')
      })
    })

    const main = document.getElementById('barba-wrapper')
    main.addEventListener('click', function(e) {
      //remove all is-acitve class
      lists.forEach(l => {
        l.classList.remove('is-active')
        l.parentElement.nextElementSibling.classList.remove('is-active')
      })
    })
  },

  onScroll: e => {
    let st = window.pageYOffset || document.documentElement.scrollTop
    const navbarHeight = document.querySelector('header').clientHeight
    if (st > self.lastScrollTop && st > navbarHeight) {
      self.body.classList.add('is-up')
      self.body.classList.remove('is-down')
    } else {
      if (st > navbarHeight) {
        self.body.classList.remove('is-up')
        self.body.classList.add('is-down')
      } else {
        self.body.classList.remove('is-up')
        self.body.classList.remove('is-down')
      }
    }
    self.lastScrollTop = st
  }
}

export default self
