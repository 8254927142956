'use strict'

import Barba from 'barba.js';
import {
  TweenMax,
  TweenLite,
  TimelineLite
} from "gsap";
import ScrollToPlugin from "gsap/umd/ScrollToPlugin";
import CustomBodymovin from './bodyMovin';

// Modules
import SwiperSlider from './swiperSlider'
import Nav from './nav'
import Accordion from './accordion'
import MapDisplay from './map'
import AreaPeopleSwitch from './areaPeopleSwitch'
import PeopleFilter from './peopleFilter'

const self = {

  lastElementClicked: null,

  transitionElms: {
    body: document.body,
    headerInner: document.querySelector('.header-inner'),
    logo: new CustomBodymovin({
      elm: document.getElementById('js-logo'),
      animationData: 'logo'
    }),
  },

  init: () => {
    Barba.Pjax.init();
    Barba.Prefetch.init();

    self.registerEvents();
    self.eachPageFunctionInit();
    self.pageTransition();
  },

  registerEvents: () => {

    // Google Analytics
    Barba.Dispatcher.on('initStateChange', function () {
      if (typeof ga === 'function') {
        ga('send', 'pageview', location.pathname);
      }
    });

    // Prevent Reloading When User click a link that is a same page as a current page
    Barba.Dispatcher.on('transitionCompleted', function (currentStatus) {
      const links = document.querySelectorAll('a[href]');
      const cbk = function (e) {
        if (e.currentTarget.href === window.location.href) {
          e.preventDefault();
          e.stopPropagation();
        }
      };
      for (let i = 0; i < links.length; i++) {
        links[i].addEventListener('click', cbk);
      }
    });

    Barba.Dispatcher.on('linkClicked', function (el) {
      self.lastElementClicked = el;
    });

  },

  pageTransition: () => {

    const PageAnimation = Barba.BaseTransition.extend({

      start: function () {

        Promise
          .all([this.newContainerLoading])
          .then(this.fadeOutIn.bind(this))
          .then(this.pageCheck.bind(this));

      },

      fadeOutIn: function () {
        const _this = this;
        const tlCover = new TimelineLite();
        const page = this.newContainer.dataset.namespace;
        const pageContent = document.querySelector('.page-content');
        const transitionWhite = document.querySelector('.transition-bg-white');

        return tlCover
          .set(transitionWhite, {
            display: 'block'
          })
          .add('out')
          .to(transitionWhite, 0.6, {
            autoAlpha: 1
          }, 'out')
          .to(this.oldContainer, 0.6, {
            autoAlpha: 0
          }, 'out')
          .add(() => {
            _this.hideDropdownNav();
            if (self.transitionElms.body.classList.contains('is-open')) {
              self.transitionElms.body.style.overflow = 'visible';
              self.transitionElms.body.classList.remove('is-open')
              TweenLite.to(self.transitionElms.headerInner, 0.3, {
                autoAlpha: 0
              });
            }
          }, 'out')
          .set(window, {
            scrollTo: 'html'
          })
          .add(() => {
            _this.done();
            _this.pageSetup(page, pageContent);
          }, '+0.6')
          .add('in')
          .to(transitionWhite, 0.6, {
            autoAlpha: 0
          }, 'in')
          .add(() => {
            _this.pageIn(page, pageContent);
          }, 'in')
          .set([transitionWhite, self.transitionElms.headerInner], {
            clearProps: 'all'
          });
      },

      hideDropdownNav: function () {
        const lists = [...document.querySelectorAll('.have-sub-nav')];
        lists.forEach((l) => {
          if (this !== l) {
            l.classList.remove('is-active');
            l.parentElement.nextElementSibling.classList.remove('is-active');
          }
        });
      },

      pageSetup: function (page, pageContent) {
        if (page == 'area') {
          TweenMax.set(['.two-columns__text', '.two-columns__img'], {
            x: '-100%',
            transformOrigin: 'left center'
          });
        } else if (page == 'home') {
          TweenMax.set('.page-title span', {
            y: '100%'
          });
          TweenMax.set('.h-top .bg-image', {
            autoAlpha: 0
          });
        } else if (pageContent) {
          TweenMax.set('.page-title span', {
            y: '100%'
          });
          TweenMax.set('.page-content', {
            autoAlpha: 0,
            y: '40px'
          });
        }
      },

      pageIn: function (page, pageContent) {
        if (page == 'area') {
          self.areaPracticeAnimationIn();
        } else if (page == 'home') {
          TweenMax.to('.h-top .bg-image', 0.6, {
            autoAlpha: 1
          });
          TweenMax.to('.page-title span', 0.6, {
            y: '0%',
            delay: 0.4
          });
        } else if (pageContent) {
          TweenMax.to('.page-title span', 0.6, {
            y: '0%'
          });
          TweenMax.to('.page-content', 0.6, {
            autoAlpha: 1,
            y: '0px',
            delay: 0.2
          });
        }
      },

      pageCheck: function () {
        const page = this.newContainer.dataset.namespace;

        setTimeout(() => {
          if (page !== 'home') {
            this.newContainer.ownerDocument.body.classList.remove('front');
          } else {
            this.newContainer.ownerDocument.body.classList.add('front');
          }
        }, 1000);

        const url = this.newContainer.baseURI;
        const navList = [...document.querySelectorAll('.menu-item a')];
        navList.forEach((list) => {
          list.classList.remove('is-page');
          if (list.href == url) {
            list.classList.add('is-page');
          }
        });
      }

    });

    Barba.Pjax.getTransition = function () {
      return PageAnimation;
    };

  },

  areaPracticeAnimationIn: () => {
    const tlCover = new TimelineLite();
    const coverLeft = document.getElementById('js-bg-cover__left');
    const coverRight = document.getElementById('js-bg-cover__right');
    const text = document.querySelector('.flex-box-center p');

    return tlCover
      .add('show')
      .to(coverLeft, 0.6, {
        x: '0%',
        ease: Sine.easeOut
      }, 'show')
      .to(coverRight, 0.6, {
        x: '0%',
        ease: Sine.easeOut
      }, 'show')
      .to(['.two-columns__text', '.two-columns__img'], 0.6, {
        x: '0%',
        ease: Sine.easeOut
      }, 'show')
      .add('leave')
      .to(coverLeft, 0.6, {
        x: '100%',
        ease: Sine.easeOut
      }, 'leave')
      .to(coverRight, 0.6, {
        x: '100%',
        ease: Sine.easeOut
      }, 'leave')
      .fromTo('.page-title span', 0.6, {
        y: '100%'
      }, {
        y: '0%'
      })
      .fromTo('.flex-box-center p', 0.6, {
        y: '30px',
        autoAlpha: 0
      }, {
        y: '0px',
        autoAlpha: 1
      }, '-=0.2');
  },

  eachPageFunctionInit: () => {

    const home = Barba.BaseView.extend({
      namespace: "home",
      onEnter: function () {
        this.SwiperSlider = null;
      },
      onEnterCompleted: function () {
        this.SwiperSlider = SwiperSlider;
        this.SwiperSlider.init();
      }
    });
    home.init();

    const area = Barba.BaseView.extend({
      namespace: "area",
      onEnter: function () {
        this.Accordion = null;
        this.SwiperSlider = null;
        this.AreaPeopleSwitch = null;
      },
      onEnterCompleted: function () {
        this.Accordion = Accordion;
        this.Accordion.init();
        this.SwiperSlider = SwiperSlider;
        this.SwiperSlider.init();
        this.AreaPeopleSwitch = AreaPeopleSwitch;
        this.AreaPeopleSwitch.init();
      }
    });
    area.init();

    const people = Barba.BaseView.extend({
      namespace: "people",
      onEnter: function () {
        this.PeopleFilter = null;
      },
      onEnterCompleted: function () {
        this.PeopleFilter = PeopleFilter;
        this.PeopleFilter.init();
      }
    });
    people.init();

    const singleLawyer = Barba.BaseView.extend({
      namespace: "s-lawyer",
      onEnter: function () {
        this.Accordion = null;
      },
      onEnterCompleted: function () {
        this.Accordion = Accordion;
        this.Accordion.init();
      }
    });
    singleLawyer.init();

    const contact = Barba.BaseView.extend({
      namespace: "contact",
      onEnter: function () {
        this.MapDisplay = null;
      },
      onEnterCompleted: function () {
        this.MapDisplay = MapDisplay;
        this.MapDisplay.init();
      }
    });
    contact.init();

  }

}

export default self