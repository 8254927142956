'use strict';

// Dependancies
import "babel-polyfill"

// Modules
import PageTransition from './modules/pageTransition'
import SwiperSlider from './modules/swiperSlider'
import Nav from './modules/nav'
import Accordion from './modules/accordion'
import MapDisplay from './modules/map'
import AreaPeopleSwitch from './modules/areaPeopleSwitch'
import PeopleFilter from './modules/peopleFilter'
import FirstLoading from './modules/firstLoading'

import {
  TweenMax
} from "gsap";
import ScrollToPlugin from "gsap/umd/ScrollToPlugin";

// Global Functionality
PageTransition.init()
Nav.init()
SwiperSlider.init()


if (document.body.classList.contains('front')) {
  FirstLoading.init();
} else {
  const loadingContainer = document.querySelector('#first-loading-container');
  loadingContainer.style.display = 'none';

  if (document.querySelector('.gform_validation_error')) {
    TweenMax.to(window, 0.6, {
      scrollTo: '.gform_validation_error'
    });
  }
  if (document.querySelector('.gform_confirmation_message')) {
    TweenMax.to(window, 0.6, {
      scrollTo: '.gform_confirmation_message'
    });
  }
}


// Conditional Functionality
const m = [{
    o: Accordion,
    i: Accordion.container
  },
  {
    o: MapDisplay,
    i: MapDisplay.container
  },
  {
    o: AreaPeopleSwitch,
    i: AreaPeopleSwitch.container
  },
  {
    o: PeopleFilter,
    i: PeopleFilter.container
  },
].map(a => (a.i) ? a.o.init() : null)