'use strict'

const self = {
  container : document.querySelector('.map'),

  init : () => {
    self.container = document.querySelector('.map');
    self.initMap(self.container);
  },

  initMap : (mapElement) => {
    let latData = Number(mapElement.dataset.lat);
		let lngData = Number(mapElement.dataset.lng);

		const myLatLng = { lat: latData, lng: lngData };
		// Styles a map in night mode.
		const map = new google.maps.Map(mapElement, {
			center: myLatLng,
			zoom: 15,
			styles: [
				{
					"stylers": [
						{
							"saturation": -100
						},
						{
							"gamma": 1
						}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.business",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.business",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.place_of_worship",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.place_of_worship",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "water",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"saturation": 50
						},
						{
							"gamma": 0
						},
						{
							"hue": "#50a5d1"
						}
					]
				},
				{
					"featureType": "administrative.neighborhood",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#333333"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "labels.text",
					"stylers": [
						{
							"weight": 0.5
						},
						{
							"color": "#333333"
						}
					]
				},
				{
					"featureType": "transit.station",
					"elementType": "labels.icon",
					"stylers": [
						{
							"gamma": 1
						},
						{
							"saturation": 50
						}
					]
				}
			]
		});
		const marker = new google.maps.Marker({
			position: myLatLng,
      map: map
		});
  }

}

export default self
