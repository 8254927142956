'use strict'

import Swiper from 'swiper'
import {
  TweenMax,
  TimelineLite
} from "gsap/umd/TweenMax";

let self

export default self = {
  container: document.querySelector('.h-descriptions .swiper-container'),
  lawyersContainer: document.querySelector('.experties [data-people="lawyers"]'),
  paralegalsContainer: document.querySelector('.experties [data-people="paralegals"]'),

  init: () => {
    self.setupElements();
    if (self.container) self.homeDesciptionSlide();
    if (self.lawyersContainer) self.expertiesSlide();
    if (self.paralegalsContainer) self.paralegalsSlide();
  },

  setupElements: () => {
    self.container = document.querySelector('.h-descriptions .swiper-container');
    self.lawyersContainer = document.querySelector('.experties .swiper-container[data-people="lawyers"]');
    self.paralegalsContainer = document.querySelector('.experties .swiper-container[data-people="paralegals"]');
  },

  expertiesSlide: () => {

    const slidesArray = [...self.lawyersContainer.querySelectorAll('.swiper-slide')];
    if (slidesArray.length < 3) {
      const mySwiper = new Swiper(self.lawyersContainer, {
        speed: 600,
        effect: 'slide',
        navigation: {
          nextEl: '.swiper-btn-arrow',
        },
        loop: false,
        slidesPerView: 3,
        breakpoints: {
          590: {
            loop: true,
            slidesPerView: 1
          },
        }
      });
    } else {
      const mySwiper = new Swiper(self.lawyersContainer, {
        speed: 600,
        effect: 'slide',
        navigation: {
          nextEl: '.swiper-btn-arrow',
        },
        loop: true,
        slidesPerView: 3,
        breakpoints: {
          590: {
            loop: true,
            slidesPerView: 1
          },
        }
      });
    }


  },

  paralegalsSlide: () => {
    const swiper = new Swiper(self.paralegalsContainer, {
      speed: 600,
      effect: 'slide',
      navigation: {
        nextEl: '.swiper-btn-arrow',
      },
      slidesPerView: 3,
      breakpoints: {
        590: {
          loop: true,
          slidesPerView: 1
        },
      }
    });

  },

  homeDesciptionSlide: () => {
    const mySwiper = new Swiper(self.container, {
      speed: 600,
      effect: 'fade',
      loop: true,
      // autoHeight: true,
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        renderBullet: (index, className) => {
          return `<div class="${className}">
            <span>${(index + 1)}</span>
            <svg viewBox="0 0 60 60" width="60" height="60" xml:space="preserve" id="svg" xmlns="http://www.w3.org/2000/svg">
              <circle id="path" cx="30" cy="30" r="15" stroke="#F68C55" fill="none" stroke-width="2" stroke-linecap="round"></circle>
            </svg>
            </div>`;
        },
        clickable: true
      },
      on: {
        paginationUpdate: function () {
          if (this.activeIndex === 4) {
            this.activeIndex = 1;
          }
          const circles = [...document.querySelectorAll('#path')];
          if (circles.length !== 0) {
            const radius = circles[0].getAttribute("r");
            const circleLength = 2 * Math.PI * radius;
            TweenMax.set(circles, {
              clearProps: 'all'
            });
            TweenMax.fromTo(circles[this.activeIndex - 1], 6, {
              strokeDashoffset: circleLength
            }, {
              strokeDashoffset: 0
            });
          }
        },
        slideChangeTransitionStart: function () {
          const texts = [...document.querySelectorAll('.h-description__text')];
          const links = [...document.querySelectorAll('.h-description__link')];

          if (texts.length !== 0) {
            const tl = new TimelineLite();
            tl
              .fromTo(texts[this.activeIndex], 1, {
                autoAlpha: 0,
                y: '20px'
              }, {
                autoAlpha: 1,
                y: 0
              })
              .fromTo(links[this.activeIndex], 1, {
                autoAlpha: 0,
                y: '20px'
              }, {
                autoAlpha: 1,
                y: 0
              }, '-=0.5')
              .set([texts[this.activeIndex], links[this.activeIndex]], {
                clearProps: 'all'
              });
          }
        }
      }
    });
  }
}