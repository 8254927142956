import bodymovin from 'bodymovin';
import logo from '../json/logo.json';
import circle from '../json/circle.json';
import firstLogingLogo from '../json/first-loading-logo.json';
import logoTransition from '../json/logo-transition.json';

export default class CustomBodymovin {
  constructor(opts={}) {
    this.elm = opts.elm || document.createElement('div');
    this.animationData = opts.animationData;
    this.WIDTH = 1920;
    this.HEIGHT = 1080;
    this.init();
    this.initListener(opts);
  }

  init() {
    this.selectAnimationData();
    this.anim = bodymovin.loadAnimation({
      container: this.elm,
      renderer: "svg",
      loop: false,
      autoplay: false,
      autoloadsegments:true,
      rendererSettings:{
        progressiveLoad:false
      },
      animationData: this.data
    });
  }

  initListener(opts) {

    this._onResize();
    window.addEventListener('resize', () => {
      this._onResize();
    },false)

    if (opts.onComplete) this.anim.addEventListener('complete', opts.onComplete)

  }

  selectAnimationData() {
    switch (this.animationData) {
      case 'logo': 
        this.data = logo; 
      break;
      case 'first-loading-logo': 
        this.data = firstLogingLogo; 
      break;
      case 'circle': 
        this.data = circle; 
      break;
      case 'transition': 
        this.data = logoTransition; 
      break;
    }
  }

  play(){
    this.anim.play();
  }
  stop(){
    this.anim.stop();
  }
  pause(){
    this.anim.pause();
  }
  destroy(){
    this.anim.destroy();
  }
  setSpeed(int){
    this.anim.setSpeed(int);
  }

  _onResize() {
    const ww = window.innerWidth;
    const wh = window.innerHeight;
    const ratio = ww / this.WIDTH;
    const scaleY = wh / (this.HEIGHT * ratio);
    const svg = this.elm.querySelector('svg');
  }

}