'use strict';
import { TweenLite, TimelineLite } from "gsap/umd/TweenMax";
import CustomBodymovin from './bodyMovin';

const self = {
  container: document.getElementById('first-loading-container'),
  headerInner: document.querySelector('.header-inner'),
  logo: null,
  circle: null,

  init : () => {
    self.setupElements();
    self.firstLoading();
  },

  setupElements : () => {
    self.container = document.getElementById('first-loading-container');
    

  },

  firstLoading : () => {
    const tlFirst = new TimelineLite();

    return tlFirst
      .add(()=>{
        self.logo = new CustomBodymovin({ 
          elm: document.getElementById('first-loading-logo'), 
          animationData: 'first-loading-logo',
          onComplete: () => {
            document.querySelector('.first-loading-logo').style.display = 'none';
            TweenLite.set('h1', {autoAlpha: 0});
            TweenLite.set(self.headerInner, {y: '-100px'});

            self.circle = new CustomBodymovin({ 
              elm: document.getElementById('first-loading-circle'), 
              animationData: 'circle',
              onComplete: () => {
                const tlLoading = new TimelineLite();
                tlLoading
                  .set(self.container, {display: 'none'})
                  .to(self.headerInner, 0.6, {y: '0px'})
                  .to('h1', 1, {autoAlpha: 1})
                  .set([self.headerInner, 'h1'], {clearProps: 'all'});;
              }
            });
            self.circle.setSpeed(0.8);
            self.circle.play();
          }
        });

        self.logo.play();
      });

  }

}

export default self