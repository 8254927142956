'use strict';

const self = {
  container: document.querySelector('.accordion'),
  titles: null,
  texts: null,

  init : () => {
    self.setupElements();
    self.accordionActive();
  },

  setupElements : () => {
    self.container = document.querySelector('.accordion');
    self.titles = [...document.querySelectorAll('.accordion__title')];
    self.texts = [...document.querySelectorAll('.accordion__text')];
  },

  accordionActive : () => {
    self.titles.forEach((title)=>{
      title.addEventListener('click', (e) => {
        const textContent = title.nextElementSibling;
        if(title.classList.contains('js-expand')){
          title.classList.remove('js-expand');
          textContent.style.maxHeight = '0px';
        } else {
          title.classList.add('js-expand');
          textContent.style.maxHeight = `${textContent.scrollHeight}px`;
        }
      });
    });
  }

}

export default self