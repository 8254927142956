'use strict';

const self = {
  container: document.querySelector('.experties'),
  titles: null,
  texts: null,

  init : () => {
    self.setupElements();
    self.accordionActive();
  },

  setupElements : () => {
    self.container = document.querySelector('.experties');
    self.titles = [...document.querySelectorAll('.experties__left h2')];
    self.people = [...document.querySelectorAll('.experties__right .swiper-container')];
  },

  accordionActive : () => {
    self.titles.forEach((title)=>{
      title.addEventListener('click', (e) => {

        const tab = e.currentTarget
        if (tab.classList.contains('is-active')) return false

        tab.parentNode.querySelector('h2.is-active').classList.remove('is-active');
        tab.classList.add('is-active');

        self.people.forEach((p)=>{
          p.classList.remove('is-show');
          if(e.currentTarget.dataset.position === p.dataset.people){
            p.classList.add('is-show');
          }
        });

      });
    });
  }

}

export default self